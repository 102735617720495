import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Person from "../components/person"
import "../styles/speakers.scss"

const Speakers = ({ data }) => (
  <Layout seoMetaTags={data?.datoCmsFrontpage?.seoMetaTags} page="Puhujat">
    {data.datoCmsSpeakerspage.notificationNode && (
      <div
        className="pageNotification"
        dangerouslySetInnerHTML={{
          __html:
            data.datoCmsSpeakerspage.notificationNode.childMarkdownRemark.html,
        }}
      ></div>
    )}
    <div className="speakers">
      {data.datoCmsSpeakerspage.speakers.map(speaker => (
        <div className="speaker" key={speaker.image.url}>
          <div className="content">
            <Person size="large" {...speaker} />
            <div
              dangerouslySetInnerHTML={{
                __html: speaker?.descriptionNode?.childMarkdownRemark.html,
              }}
              className="description"
            />
          </div>
        </div>
      ))}
    </div>
  </Layout>
)
export const query = graphql`
  query SpeakersQuery {
    datoCmsSpeakerspage {
      notificationNode {
        childMarkdownRemark {
          html
        }
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      speakers {
        ...personFields
      }
    }
  }
`

export default Speakers
